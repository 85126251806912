import React from 'react';
import TypeIcon from './TypeIcon';

const EffectivenessSection = ({ title, types, multiplier }) => {
  if (types.length === 0) return null;

  return (
    <div className="mb-4">
      <h3 className="text-xl font-semibold mb-1">{title} ({multiplier}x):</h3>
      <div className="flex flex-wrap gap-3 mb-4 max-w-sm">
        {types.map(type => (
          <TypeIcon key={type} type={type} />
        ))}
      </div>
    </div>
  );
};

export default function ResultDisplay({ results }) {
  if (!results) return null;

  const effectivenessCategories = [
    { key: 'double_super_effective', title: 'Double Super Effective', multiplier: 2.56 },
    { key: 'super_effective', title: 'Super Effective', multiplier: 1.6 },
    { key: 'normal_effective', title: 'Normal Effective', multiplier: 1.0 },
    { key: 'not_very_effective', title: 'Not Very Effective', multiplier: 0.625 },
    { key: 'double_not_very_effective', title: 'Double Not Very Effective', multiplier: 0.391 },
    { key: 'ineffective', title: 'Ineffective', multiplier: 0 },
  ];

  return (
    <div className="mt-4 p-4 bg-gray-100 rounded-lg w-full">
      <h3 className="text-2xl font-semibold mb-4">{results.attacking_types}</h3>
      {effectivenessCategories.map(category => (
        <EffectivenessSection
          key={category.key}
          title={category.title}
          types={Object.keys(results[category.key] || {})}
          multiplier={category.multiplier}
        />
      ))}
    </div>
  );
}
