import React from 'react';
import TypeIcon from './TypeIcon';

export default function TypeSelector({ types, selectedTypes, onTypeSelect }) {
  return (
    <div className="flex flex-wrap justify-center gap-3 mb-4">
      {types.map(type => (
        <TypeIcon
          key={type}
          type={type}
          isSelected={selectedTypes.includes(type)}
          onSelect={() => onTypeSelect(type)}
        />
      ))}
    </div>
  );
}
