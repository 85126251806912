import React, { useState, useEffect } from 'react';
import { Head } from '@inertiajs/react';
import axios from 'axios';
import TypeSelector from './TypeSelector';
import ResultDisplay from './ResultDisplay';

export default function App({ pokemonTypes }) {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (selectedTypes.length > 0) {
      fetchResults();
    } else {
      setResults(null);
    }
  }, [selectedTypes]);

  const fetchResults = async () => {
    try {
      const response = await axios.post('/type_effectivenesses', { types: selectedTypes });
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };

  const handleTypeSelect = (type) => {
    setSelectedTypes(prev => {
      if (prev.includes(type)) {
        return prev.filter(t => t !== type);
      } else if (prev.length < 2) {
        return [...prev, type];
      }
      return prev;
    });
  };

  return (
    <>
      <Head title="Pokémon Go Type Helper" />
      <div className="container mx-auto p-4 flex flex-col items-center max-w-sm md:max-w-lg">
        <h1 className="text-3xl font-bold mb-4 text-center">Pokémon Go Type Helper</h1>
        <TypeSelector
          types={pokemonTypes}
          selectedTypes={selectedTypes}
          onTypeSelect={handleTypeSelect}
        />
        {selectedTypes.length > 0 ? (
          <ResultDisplay results={results} />
        ) : (
          <p className="mt-4 text-lg">Select one or two Pokémon types to see their effectiveness.</p>
        )}
      </div>
    </>
  );
}
