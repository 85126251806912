import React, { useState } from 'react';

import bugSvg from '/assets/pokemon_types/bug.svg'
import darkSvg from '/assets/pokemon_types/dark.svg'
import dragonSvg from '/assets/pokemon_types/dragon.svg'
import electricSvg from '/assets/pokemon_types/electric.svg'
import fairySvg from '/assets/pokemon_types/fairy.svg'
import fightingSvg from '/assets/pokemon_types/fighting.svg'
import fireSvg from '/assets/pokemon_types/fire.svg'
import flyingSvg from '/assets/pokemon_types/flying.svg'
import ghostSvg from '/assets/pokemon_types/ghost.svg'
import grassSvg from '/assets/pokemon_types/grass.svg'
import groundSvg from '/assets/pokemon_types/ground.svg'
import iceSvg from '/assets/pokemon_types/ice.svg'
import normalSvg from '/assets/pokemon_types/normal.svg'
import poisonSvg from '/assets/pokemon_types/poison.svg'
import psychicSvg from '/assets/pokemon_types/psychic.svg'
import rockSvg from '/assets/pokemon_types/rock.svg'
import steelSvg from '/assets/pokemon_types/steel.svg'
import waterSvg from '/assets/pokemon_types/water.svg'

export default function TypeIcon({ type, isSelected, onSelect }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const typeIcons = {
    bug: bugSvg,
    dark: darkSvg,
    dragon: dragonSvg,
    electric: electricSvg,
    fairy: fairySvg,
    fighting: fightingSvg,
    fire: fireSvg,
    flying: flyingSvg,
    ghost: ghostSvg,
    grass: grassSvg,
    ground: groundSvg,
    ice: iceSvg,
    normal: normalSvg,
    poison: poisonSvg,
    psychic: psychicSvg,
    rock: rockSvg,
    steel: steelSvg,
    water: waterSvg
  };

  return (
    <div className="relative">
      <div
        className={`w-12 h-12 md:w-16 md:h-16 rounded flex items-center justify-center bg-pokemon-${type} select-none cursor-pointer duration-150 transition-all
          ${isSelected ? 'ring-4 ring-violet-700 ring-opacity-75 shadow-lg shadow-black' : 'shadow-md shadow-gray-400'}`}
        onClick={onSelect}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <img
          src={typeIcons[type]}
          alt={`${type} type`}
          className="w-8 h-8 filter brightness-0 invert"
        />
      </div>
      {showTooltip && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap">
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </div>
      )}
    </div>
  );
}
